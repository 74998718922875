import ALL_USERS from '@/graphql/user/query/getAllUsersAutocomplete.gql'

const users = ref<{label: string; value: string}[]>([])
const parentUsers = ref<{label: string; value: string}[]>([])
const key = ref(0)
const parentKey = ref(0)
const firstTime = ref(true)
const parentFirstTime = ref(true)

export async function usersAutocomplete(params: any) {
  const apolloQuery = ALL_USERS
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: 'FIRST_NAME',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchUsersCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await usersAutocomplete({ search: queryString })
    cb(results?.getAllUsers?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchUsersCb')
  }
}

export function getSearchUsers(entityUser: any, entityUsers: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchUsers('', entityUser, entityUsers)
  }

  return users?.value.filter(i => i.label) ?? []
}

export function getSearchParentUsers(entityUser: any, entityUsers: any) {
  if (parentFirstTime.value === true) {
    parentFirstTime.value = false
    searchParentUsers('', entityUser, entityUsers)
  }

  return parentUsers?.value.filter(i => i.label) ?? []
}

function addRemoteEntityToArray(remoteArray: any, entityUser: any, entityUsers: any, refresh = false, isParent = false) {
  const usersToAdd: { label: any; value: any }[] = []

  entityUsers?.forEach((j: any) => {
    if (!remoteArray.find((k: any) => k.value === j.uuid)) {
      usersToAdd.push({
        label: `${j.first_name ?? ''} ${j.last_name ?? ''}`,
        value: j.uuid
      })
    }
  })

  if (entityUser && !remoteArray.find((k: any) => k.value === entityUser.uuid)) {
    usersToAdd.push({
      label: `${entityUser.first_name ?? ''} ${entityUser.last_name ?? ''}`,
      value: entityUser.uuid
    })
  }

  if (refresh) {
    key.value = 0
    parentKey.value = 0
  }

  if (isParent && parentKey.value === 0) {
    parentKey.value = setCustomKeyVariable()
  } else if (!isParent && key.value === 0) {
    key.value = setCustomKeyVariable()
  }

  return [...remoteArray, ...usersToAdd]
}

export async function searchUsers(queryString: any, entityUser: any, entityUsers: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await usersAutocomplete({ search: queryString })
    const remoteUsers = results?.getAllUsers?.data?.map((i: any) => {
      return {
        label: `${i.first_name ?? ''} ${i.last_name ?? ''}`,
        value: i.uuid
      }
    }) ?? []

    users.value = addRemoteEntityToArray(remoteUsers, entityUser, entityUsers, refresh, false)
    firstTime.value = false

    if (entityUser) {
      getSearchUsers(entityUser, entityUsers)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchUsers')
  }
}

export async function searchParentUsers(queryString: any, entityUser: any, entityUsers: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await usersAutocomplete({ search: queryString })
    const remoteUsers = results?.getAllUsers?.data?.map((i: any) => {
      return {
        label: `${i.first_name ?? ''} ${i.last_name ?? ''}`,
        value: i.uuid
      }
    }) ?? []

    parentUsers.value = addRemoteEntityToArray(remoteUsers, entityUser, entityUsers, refresh, true)
    parentFirstTime.value = false

    if (entityUser) {
      getSearchParentUsers(entityUser, entityUsers)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchParentUsers')
  }
}

export function getUserKey () {
  return key.value
}

export function getParentUserKey () {
  return parentKey.value
}

export function initialUsers () {
  users.value = []
  parentUsers.value = []
  key.value = 0
  parentKey.value = 0
  firstTime.value = true
  parentFirstTime.value = true
}